<template>
  <b-modal
    v-model="showModal"
    id="add-attachments-modal"
    centered
    hide-footer
    hide-header-close
    no-close-on-backdrop
    :title="submitButtonText"
    close
    :size="filelist.length ? 'lg' : ''">
    <b-form @submit.prevent="onSubmit">
      <b-row class="mt-2 px-3">
        <b-col sm="12" xl="8" v-if="this.filelist.length" v-cloak>
          <b-list-group v-if="this.filelist.length">
            <b-list-group-item class="d-inline-flex p-2" v-for="(file, i) in filelist" :key="i">
              <b-form-input placeholder="File name..." v-model="filelist[i].fileName" required />
              <b-button variant="outline-danger" size="sm" @click="remove(filelist.indexOf(file))">
                <b-icon-trash></b-icon-trash>
              </b-button>
            </b-list-group-item>
          </b-list-group>
        </b-col>

        <b-col v-if="shouldShowDragNDrop" class="file-drop-column my-4 my-xl-0 p-0">
          <div class="file-drop-area bg-light" @dragover="dragover" @drop="drop">
            <input
              type="file"
              accept="image/jpeg,image/png,image/jpg,image/svg+xml/webp"
              :multiple="!isSingle"
              name="file-input"
              id="file-input"
              class="file-input invisible"
              @change="onChange" />

            <label for="file-input" class="w-100 text-center cursor-pointer text-muted m-0 px-3 py-5">
              <p>
                <b-icon-cloud-upload-fill font-scale="3"></b-icon-cloud-upload-fill>
              </p>
              <div>
                <p class="mb-0">Przenieś plik tutaj</p>
                <p class="mb-0">lub <span class="text-info">wybierz z dysku</span></p>
              </div>
            </label>
          </div>
        </b-col>
      </b-row>

      <div class="float-right mt-4">
        <b-button variant="secondary" @click="closeModal" class="mr-3"> Anuluj </b-button>
        <b-button type="submit" variant="success"> {{ submitButtonText }} </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { base64Encode } from '@/constants/utils/file';

export default {
  name: 'FileUpload',
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    isSingle: {
      type: Boolean,
      required: false,
      default: true,
    },
    files: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      filelist: [],
    };
  },

  watch: {
    files() {
      this.filelist = this.files;
    },
  },

  computed: {
    shouldShowDragNDrop() {
      if (this.isSingle && this.filelist.length === 1) {
        return false;
      }

      return true;
    },
    submitButtonText() {
      return this.isSingle ? 'Dodaj plik' : 'Dodaj pliki';
    },
  },

  methods: {
    onSubmit() {
      this.$emit('submit', this.filelist);
      this.$emit('closeModal');
    },
    onChange(event) {
      const fileArray = [...event.target.files];

      fileArray.forEach(async (file) => {
        const extension = file.name.split('.').pop();
        const fileName = file.name.replace(/\.[^/.]+$/, '') + '.' + extension;
        const preview = await base64Encode(file);

        this.filelist.push({ fileName, extension, file, preview });
      });
    },
    closeModal() {
      this.$emit('closeModal');
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
    },
    drop(event) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      this.onChange({ target: { files } });
    },
  },
};
</script>

<style lang="scss">
div#add-attachments-modal {
  div.modal-dialog {
    transition: max-width 0.3s ease-out, transform 0.3s ease-out;
  }
  .col.file-drop-column {
    flex-shrink: 1;
  }
  div.file-drop-area {
    border: 2px dashed rgba(0, 0, 0, 0.25);
    input.file-input {
      position: absolute;
      height: 0;
    }
  }
}
</style>
